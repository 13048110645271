import { Provider as NiceModalProvider } from '@ebay/nice-modal-react'
import { useOs } from '@wpp-open/react'
import { Suspense, lazy } from 'react'
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom'

import 'app/global.module.scss'
import ErrorBoundary from 'components/common/errorBoundary/ErrorBoundary'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { AppPermissions } from 'types/permissions/permissions'
import { routesManager } from 'utils/routesManager'

// Lazy-loaded components with explicit named exports handling
const NotFound = lazy(() => import('pages/404/NotFound').then(module => ({ default: module.NotFound })))
const MiroAuthCallback = lazy(() =>
  import('pages/components/projectModal/components/integrations/miro/MiroAuthCallback').then(module => ({
    default: module.MiroAuthCallback,
  })),
)
const TasksDashboardPage = lazy(() =>
  import('pages/dashboard/components/tasksDashboard/TasksDashboard').then(module => ({
    default: module.TasksDashboardPage,
  })),
)
const DashboardPage = lazy(() =>
  import('pages/dashboard/Dashboard').then(module => ({ default: module.DashboardPage })),
)
const ProjectDemo = lazy(() =>
  import('pages/projectDemo/ProjectDemo').then(module => ({ default: module.ProjectDemo })),
)

const ProjectRoutes = lazy(() => import('app/ProjectRoutes').then(module => ({ default: module.default })))
const TemplateRoutes = lazy(() => import('app/TemplateRoutes').then(module => ({ default: module.default })))

const ProjectPage = lazy(() => import('pages/project/ProjectPage').then(module => ({ default: module.ProjectPage })))
const Project = lazy(() => import('pages/project/Project').then(module => ({ default: module.Project })))
const Template = lazy(() => import('pages/template/Template').then(module => ({ default: module.Template })))
const Settings = lazy(() => import('pages/settings/Settings').then(module => ({ default: module.Settings })))

const TemplatePage = lazy(() =>
  import('pages/template/TemplatePage').then(module => ({ default: module.TemplatePage })),
)
const Templates = lazy(() => import('pages/templates/Templates').then(module => ({ default: module.Templates })))

const PermittedAccess = ({ permission }: { permission: AppPermissions | AppPermissions[] }) => {
  const { isPermitted } = useIsPermitted()
  return isPermitted(permission) ? <Outlet /> : <Navigate replace to={routesManager['404'].root.pattern} />
}

export function App() {
  const { osContext } = useOs()

  // @TODO: Remove after fix WPPLONOP-12848
  if (!osContext.baseUrl) return null

  return (
    <BrowserRouter basename={osContext.baseUrl}>
      <NiceModalProvider>
        <ErrorBoundary>
          <Suspense fallback={null}>
            <Routes>
              <Route index element={<Navigate to={routesManager.projects.root.pattern} />} />

              <Route path={`${routesManager.projects.root.pattern}/*`} element={<DashboardPage />} />
              <Route path={routesManager.miroAuthCallback.pattern} element={<MiroAuthCallback />} />

              <Route path={routesManager.project.root.pattern} element={<ProjectPage />}>
                <Route path={routesManager.project.demo.shortPattern} element={<ProjectDemo />} />
                <Route path="" element={<Project />}>
                  <Route path="*" element={<ProjectRoutes />} />
                </Route>
              </Route>

              <Route path={routesManager.template.root.pattern} element={<TemplatePage />}>
                <Route path="" element={<Template />}>
                  <Route path="*" element={<TemplateRoutes />} />
                </Route>
              </Route>

              <Route
                path={routesManager.templates.root.pattern}
                element={
                  <PermittedAccess
                    permission={[
                      AppPermissions.ORCHESTRATION_GLOBAL_MANAGE,
                      AppPermissions.ORCHESTRATION_PROJECTS_CREATE,
                      AppPermissions.ORCHESTRATION_WORKFLOW_TEMPLATE_VIEW,
                    ]}
                  />
                }
              >
                <Route index element={<Templates />} />
              </Route>

              <Route path={routesManager.tasks.root.pattern} element={<TasksDashboardPage />} />

              <Route
                path={`${routesManager.settings.root.pattern}/*`}
                element={
                  <PermittedAccess
                    permission={[
                      AppPermissions.ORCHESTRATION_GLOBAL_MANAGE,
                      AppPermissions.ORCHESTRATION_WORKSPACE_ATTRIBUTE_MANAGE,
                    ]}
                  />
                }
              >
                <Route path="*" element={<Settings />} />
              </Route>

              <Route path={routesManager['404'].root.pattern} element={<NotFound />} />

              <Route path="*" element={<Navigate replace to={routesManager.projects.root.pattern} />} />
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </NiceModalProvider>
    </BrowserRouter>
  )
}
