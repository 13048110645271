import { WppActionButton, WppTypography } from '@platform-ui-kit/components-library-react'
import React, { Component, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import styles from 'components/common/errorBoundary/ErrorBoundary.module.scss'
import { Flex } from 'components/common/flex/Flex'

interface ErrorBoundaryProps {
  children: ReactNode
  fallback?: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
}

const FallbackUI = () => {
  const { t } = useTranslation()

  const reloadPage = () => {
    window.location.reload()
  }

  return (
    <Flex direction="column" align="center" justify="center" gap={24} className={styles.container}>
      <WppTypography type="2xl-heading">{t('common.generic_error')}</WppTypography>

      <WppActionButton onClick={reloadPage} variant="primary" data-testid="reload-button">
        {t('common.reload')}
      </WppActionButton>
    </Flex>
  )
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = { hasError: false }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('ErrorBoundary caught an error:', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback || <FallbackUI />
    }

    return this.props.children
  }
}

export default ErrorBoundary
