export enum AppPermissions {
  ORCHESTRATION_APP_ACCESS = 'ORCHESTRATION_APP_ACCESS',
  ORCHESTRATION_PROJECTS_CREATE = 'ORCHESTRATION_PROJECTS_CREATE',
  ORCHESTRATION_GLOBAL_MANAGE = 'ORCHESTRATION_GLOBAL_MANAGE',
  ORCHESTRATION_WORKFLOW_TEMPLATE_VIEW = 'ORCHESTRATION_WORKFLOW_TEMPLATE_VIEW',
  ORCHESTRATION_WORKFLOW_TEMPLATE_CREATE = 'ORCHESTRATION_WORKFLOW_TEMPLATE_CREATE',
  ORCHESTRATION_WORKSPACE_ATTRIBUTE_MANAGE = 'ORCHESTRATION_WORKSPACE_ATTRIBUTE_MANAGE',
  ORCHESTRATION_PINBOARD_TAB_ACCESS = 'ORCHESTRATION_PINBOARD_TAB_ACCESS',
  ORCHESTRATION_PROJECTS_CREATE_FROM_SCRATCH = 'ORCHESTRATION_PROJECTS_CREATE_FROM_SCRATCH',
}

export enum ProjectRole {
  OWNER = 'OWNER',
  CONTRIBUTOR = 'CONTRIBUTOR',
  VIEWER = 'VIEWER',
  EXTERNAL = 'EXTERNAL',
}
