import { isDevelop } from 'utils/common'

export const getDevEnv = (): string | null => {
  if (!isDevelop) {
    return null
  }
  if (window.location.pathname.split('/')[1] !== 'local') {
    return null
  }

  const result = window.location.origin.match(/-([a-z]+)\.os-dev\.io$/)
  return (result && result[1]) || null
}
